<template>
  <b-container fluid>
    <b-card
      bg-variant="light"
      ref="stickyFilters"
      class="my-3"
      :style="stickyStyles"
    >
      <b-row>
        <b-col md="2">
          <b-form-group :label="`${$t('projects.filter.filter')}:`">
            <b-form-input
              v-model="filterSearch"
              debounce="400"
              :placeholder="$t('projects.filter.filter-placeholder')"
            />
            <b-input-group-append v-if="filterSearch">
              <b-btn @click="filterSearch = ''">&times;</b-btn>
            </b-input-group-append>
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group :label="`${$t('projects.filter.date-from')}:`">
            <date-picker v-model="filterDateFrom" />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group :label="`${$t('projects.filter.date-to')}:`">
            <date-picker v-model="filterDateTo" />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group>
            <template #label>&nbsp;</template>
            <b-form-checkbox class="pt-1" v-model="filterUseAdvancedSearch">
              {{ $t('filters.advanced.toggle') }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group class="mb-0">
            <template slot="label">
              {{ $t('projects.file-package.label') }}:
            </template>
            <b-form-checkbox class="pt-1" v-model="withMediafiles">
              {{ $t('projects.file-package.with-media-files') }}
            </b-form-checkbox>
            <b-btn
              id="download-report-btn"
              block
              @click="downloadReport"
              :disabled="downloadDisabled"
            >
              <b-spinner
                v-if="exportState == 'loading'"
                class="gid-spinner--button mr-2"
              ></b-spinner>
              {{ $t('projects.file-package.generate') }}
            </b-btn>
            <b-popover
              target="download-report-btn"
              placement="bottom"
              :variant="exportState"
            >
              <template #title>
                {{ $t(`projects.file-package.action-${exportState}`) }}
              </template>
              {{
                $t(
                  exportState == 'success'
                    ? 'projects.file-package.explain'
                    : exportError,
                )
              }}
            </b-popover>
            <small class="text-muted">
              {{ $t('projects.file-package.count', { totalRows }) }}
            </small>
          </b-form-group>
        </b-col>
      </b-row>
      <advanced-search
        v-if="filterUseAdvancedSearch"
        v-model="filterAdvancedSearch"
        :filter-options="advancedFilterOptions"
      />
    </b-card>

    <b-table
      :show-empty="!loading"
      striped
      ref="wrapper"
      stacked="md"
      class="gid-table-infinite"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDir"
      no-local-sorting
    >
      <template #cell(last_status_change_days)="{ item }">
        <DaysSinceLastChange :days="item.last_status_change_days" />
      </template>
      <template #cell(project_name)="{ item }">
        {{ item.opportunity.name }}
        <small class="d-block text-muted">{{ item.name }}</small>
      </template>
      <template #cell(order_type)="{ item }">{{ item.order_type }}</template>
      <template #cell(car_delivery)="{ item }">
        {{ item.car_delivery | moment('L') }}
        <div class="d-flex flex-column">
          <small v-if="item.car_type">Car Type: {{ item.car_type }}</small>
          <small v-if="item.charging_opportunity">
            Charging Possibilities: {{ item.charging_opportunity }}
          </small>
          <div class="d-flex">
            <DaysSinceLastChange
              v-if="item.score"
              :days="item.score"
            ></DaysSinceLastChange>
            <span
              class="mx-2"
              v-if="item.urgent_reason"
              v-b-tooltip.hover
              :title="item.urgent_reason"
            >
              ⚠️
            </span>
          </div>
        </div>
      </template>
      <template #cell(project_manager)="{ item }">
        <span
          v-if="
            !contactProfiles.includes('admin') && item.project_managers.length
          "
        >
          <span v-for="(pm, index) in item.project_managers" :key="index">
            {{ `${pm.first_name} ${pm.last_name}` }}
          </span>
        </span>
        <span
          v-if="
            !contactProfiles.includes('admin') && !item.project_managers.length
          "
        >
          None
        </span>
        <project-manager-dropdown
          :value="selectedProjectManager(item)"
          :projectId="item.id"
          :setProjectManager="setProjectManager"
          :projectManagers="projectManagers"
          v-if="contactProfiles.includes('admin')"
        />
      </template>
      <template #cell(customer_name)="{ item }">
        <span v-for="(customer, index) in item.customers" :key="customer.id">
          {{
            `${customer.name}${index == item.customers.length - 1 ? '' : ', '}`
          }}
          <br />
          {{ customer.eid }}
        </span>
      </template>
      <template #cell(project_value)="{ item }">
        <span>
          {{
            item.price_selling_sum &&
            `${item.price_selling_sum.toFixed(2)} &euro;`
          }}
        </span>
      </template>
      <template #cell(creation_date)="{ item }">
        {{ item.jobs.length && item.jobs[0].job.created_date | moment('L') }}
      </template>

      <template #cell(documents)="{ item, toggleDetails }">
        <b-button class="mr-2" size="sm" @click.stop="toggleDetails">
          {{ $t('projects.details') }}
        </b-button>
        <b-button
          size="sm"
          class="mr-1"
          :to="`/projects/${item.id}`"
          target="_blank"
        >
          Report
        </b-button>
      </template>
      <template #row-details="item">
        <project-details :item="item" />
      </template>
      <template #custom-foot="{ columns }" v-if="loading">
        <b-tr v-for="row in perPage" :key="row">
          <b-td v-for="col in columns" :key="col">
            <b-skeleton :width="`${Math.round(Math.random() * 50) + 50}%`" />
          </b-td>
        </b-tr>
      </template>
    </b-table>
    <div class="gid-results-count__holder d-flex">
      <b-button
        pill
        v-if="stickyFiltersButton"
        @click="toggleSitckyFilters"
        :variant="stickyFiltersVisible ? 'primary' : 'secondary'"
        class="mr-2"
      >
        <BIconSliders />
      </b-button>
      <div class="rounded-pill gid-results-count">
        <span :class="{ 'gid-results-count--loading': loading }">
          <template v-if="totalRows !== null">
            {{ $t('results') }}: {{ items.length }} / {{ totalRows || 0 }}
          </template>
          <BIconThreeDots v-else />
        </span>
      </div>
    </div>
  </b-container>
</template>
<script>
import axios from 'axios';
import { BIconSliders, BIconThreeDots } from 'bootstrap-vue';

import { mapGetters } from 'vuex';
import { mapFilters } from '@gid/vue-common/store/utils';

import DatePicker from '@gid/vue-common/components/DatePicker.vue';
import ProjectDetails from '../components/ProjectDetails.vue';
import ProjectManagerDropdown from '../components/ProjectManagerDropdown.vue';
import DaysSinceLastChange from '@gid/vue-common/components/DaysSinceLastChange';
import AdvancedSearch from '@gid/vue-common/components/filters/AdvancedSearch.vue';
import AdvancedFilterOptionsMixin from '../mixins/AdvancedFilterOptionsMixin';
import {
  AccountProfileEnum,
  ContactProfileEnum,
  UserRoleEnum,
} from '@gid/models';

import { SET_REPORTS_FILTER } from '@gid/vue-common/store/reports.module';
import InfiniteScrollMixin from '@gid/vue-common/components/mixins/InfiniteScrollMixin';
import Api from '@gid/vue-common/api';

export default {
  mixins: [AdvancedFilterOptionsMixin, InfiniteScrollMixin],
  components: {
    AdvancedSearch,
    DatePicker,
    ProjectDetails,
    ProjectManagerDropdown,
    DaysSinceLastChange,
    BIconSliders,
    BIconThreeDots,
  },
  async created() {
    this.loadData();
    const allowedContactProfiles = [
      ContactProfileEnum.ADMIN,
      ContactProfileEnum.REPORTING,
      ContactProfileEnum.PROJECT_MANAGER_GENERIC,
      ContactProfileEnum.PROJECT_MANAGER_DEFAULT,
    ];
    const contactProfiles = this.user?.contact.profiles || [];
    if (
      contactProfiles.some((p) => allowedContactProfiles.includes(p)) &&
      this.user?.account?.profiles?.includes(AccountProfileEnum.PROJECT_VIEW) &&
      this.user?.role === UserRoleEnum.BRAND
    ) {
      await this.fetchProjectManagers();
      this.advancedFilterOptions = this.advancedFilterOptions.filter(
        (entry) => entry.type != 'job_type',
      );
    } else {
      this.$router.push('/jobs');
    }
  },
  data() {
    return {
      fields: [
        {
          label: 'flow-slas',
          key: 'last_status_change_days',
          sortable: true,
        },
        {
          label: 'order-type',
          key: 'order_type',
        },
        {
          label: this.$t('projects.table.heading.project-id'),
          key: 'project_name',
        },
        {
          label: this.$t('projects.table.heading.car-delivery-date'),
          key: 'car_delivery',
          sortable: true,
        },
        {
          lable: this.$t('projects.table.heading.project-manager'),
          key: 'project_manager',
        },
        {
          lable: this.$t('projects.table.heading.project-value'),
          key: 'project_value',
        },
        {
          lable: this.$t('projects.table.heading.customer'),
          key: 'customer_name',
        },
        {
          lable: this.$t('projects.table.heading.created-date'),
          key: 'creation_date',
        },
        {
          lable: this.$t('projects.table.heading.documentation'),
          key: 'documents',
        },
      ],
      items: [],
      perPage: 10,
      totalRows: null,
      sortBy: null,
      sortDir: true,
      loading: 0,
      projectManagers: [],
      selectedPM: null,
      exportState: 'available',
      exportError: null,
      withMediafiles: false,
    };
  },
  computed: {
    ...mapGetters(['locale', 'contactProfiles', 'user']),
    ...mapFilters({
      moduleName: 'reports',
      states: [
        'search',
        'dateFrom',
        'dateTo',
        'status',
        'useAdvancedSearch',
        'advancedSearch',
      ],
      setMutation: SET_REPORTS_FILTER,
      setCallback: 'filterUpdated',
    }),
    downloadDisabled() {
      return this.totalRows == 0 || this.exportState != 'available';
    },
    selectedProjectManager() {
      return (item) => {
        if (item && item.project_managers.length) {
          return item.project_managers.map((entry) => ({
            value: entry.sfid,
            text: `${entry.first_name} ${entry.last_name}`,
          }))[0];
        }
        return {};
      };
    },
  },
  watch: {
    sortBy() {
      this.loadData();
    },
    sortDir() {
      this.loadData();
    },
    filterSearch(nextVal, prevVal) {
      if (JSON.stringify(prevVal) !== JSON.stringify(nextVal)) {
        this.$nextTick(() => this.loadData());
      }
    },
    filterUseAdvancedSearch(nextVal, prevVal) {
      if (JSON.stringify(prevVal) !== JSON.stringify(nextVal)) {
        this.$nextTick(() => this.loadData());
      }
    },
  },
  methods: {
    async loadData(append = false) {
      if (!append) {
        this.totalRows = null;
        this.items = [];
      }
      if (this.totalRows !== null && this.items.length >= this.totalRows) {
        return;
      }
      this.loading = (this.totalRows ?? this.perPage) - this.items.length;
      if (this.loading > this.perPage) {
        this.loading = this.perPage;
      }
      this.loading = true;
      const query = {
        page_size: this.perPage,
        page_number: 0,
        search: this.filterSearch.trim(),
        from_date: this.filterDateFrom,
        to_date: this.filterDateTo,
        status: this.filterStatus,
        sort_by: this.sortBy ? this.sortBy : undefined,
        sort_dir: this.sortDir ? 'desc' : 'asc',
        excluding: this.items.map(({ id }) => id),
      };

      if (this.filterUseAdvancedSearch && this.filterAdvancedSearch) {
        query.search_json = JSON.stringify(this.filterAdvancedSearch);
      }

      Api.post(`/api/brand/projects`, query, {
        autoCancelScope: this,
      })
        .then((response) => {
          this.totalRows =
            this.items.length + response.data.pagination.total_count;
          this.items.push(...response.data.data);
        })
        .finally(() => {
          this.loading = 0;
        });
    },
    async fetchProjectManagers() {
      const response = await axios.get(`/api/brand/project-managers`);
      this.projectManagers = response.data.map((entry) => ({
        value: entry.sfid,
        text: `${entry.first_name} ${entry.last_name}`,
      }));
    },
    async setProjectManager(projectId, pmId) {
      try {
        await axios.put(`/api/brand/projects/set-project-owner`, {
          project_id: `${projectId}`,
          contact_sfid: pmId,
        });
      } catch (e) {
        throw new Error(e);
      }
    },
    async downloadReport() {
      const query = {
        search: this.filterSearch.trim(),
        from_date: this.filterDateFrom,
        to_date: this.filterDateTo,
        with_mediafiles: this.withMediafiles,
      };
      if (this.filterUseAdvancedSearch && this.filterAdvancedSearch) {
        query.search_json = JSON.stringify(this.filterAdvancedSearch);
      }
      this.exportState = 'loading';
      try {
        await axios.post(`/api/brand/projects/export`, query);
        this.exportState = 'success';
      } catch (error) {
        this.exportState = 'danger';
        this.exportError = error.response.data;
      }
      this.$root.$emit('bv::show::popover', 'download-report-btn');
      setTimeout(() => {
        window.addEventListener('click', this.hidePopover);
      }, 100);
    },
    hidePopover() {
      this.$root.$emit('bv::hide::popover', 'download-report-btn');
      window.removeEventListener('click', this.hidePopover);
    },
    filterUpdated() {
      this.exportState = 'available';
      this.$nextTick(() => this.loadData());
    },
  },
};
</script>
