<template>
  <b-container fluid>
    <b-row class="mb-3">
      <b-col md="2">
        <h4>{{ $t('jobs.table.heading.opportunity') }}:</h4>
      </b-col>
      <b-col align-self="center">
        <span left>{{ item.item.opportunity.name }}</span>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="mx-3" v-if="item.item.products.length">
        <ProductsList
          :item="item.item"
          :getCurrentProductOrder="getCurrentProductOrder"
          @toggleModal="toggleModal"
        />
      </b-col>
      <b-col v-if="item.item.jobs.length">
        <h4>{{ $t('projects.jobs') }}</h4>
        <div class="table-responsive">
          <table class="table table-borderless table-responsive-stack">
            <thead>
              <th scope="col">{{ $t('projects.jobs-table.name') }}</th>
              <th scope="col">{{ $t('projects.jobs-table.external-ids') }}</th>
              <th scope="col">{{ $t('projects.jobs-table.created') }}</th>
              <th scope="col">{{ $t('projects.jobs-table.open') }}</th>
              <th scope="col">{{ $t('projects.jobs-table.accepted') }}</th>
              <th scope="col">
                {{ $t('projects.jobs-table.appointment-confirmed') }}
              </th>
              <th scope="col">{{ $t('projects.jobs-table.appointment') }}</th>
              <th scope="col">{{ $t('projects.jobs-table.started') }}</th>
              <th scope="col">{{ $t('projects.jobs-table.finished') }}</th>
              <th scope="col">{{ $t('projects.jobs-table.completed') }}</th>
              <th scope="col">{{ $t('projects.jobs-table.status') }}</th>
              <th scope="col">{{ $t('projects.jobs-table.step') }}</th>
            </thead>
            <tbody>
              <tr
                v-for="(job, index) in item.item.jobs"
                :key="index"
                class="bg-transparent"
                scope="row"
              >
                <td>
                  {{ job.job.name }}
                  <p v-if="shouldDisplayQuote(job)">
                    <a href="#" @click.prevent="getQuotePath(job.job.sfid)">
                      {{ $t('view-quote') }}
                    </a>
                  </p>
                  <p v-if="shouldDisplayBrandLink(job)">
                    <a
                      href="#"
                      @click.prevent="getBrandQuotePath(job.job.sfid)"
                    >
                      {{ $t('view-brand-quote') }}
                    </a>
                  </p>
                </td>
                <td>
                  <template v-if="job.job.eid">
                    {{ job.job.eid }}
                    <br />
                  </template>
                  <b-badge v-if="isDKVMissingData(job)" variant="danger">
                    {{ $t('projects.missing-data') }}
                  </b-badge>
                  <a
                    v-if="!edit_eid_modal.loading"
                    href="#edit-eid"
                    @click.prevent="changeEID(job.job)"
                  >
                    {{ job.job.invoicing_eid || $t('projects.invoice-id.add') }}
                  </a>
                  <b-spinner v-else variant="primary" />
                </td>
                <td>
                  <template v-if="job.job.created_date">
                    {{ job.job.created_date | moment('L') }}
                  </template>
                  <template v-else>-</template>
                </td>
                <td>
                  <template>
                    {{ latestOpenStatus(job) | moment('L') }}
                  </template>
                </td>
                <td>
                  <template
                    v-if="
                      getJobToStatusTimestamp(
                        (logs = job.logs),
                        (toStatus = 'accepted'),
                      )
                    "
                  >
                    {{
                      getJobToStatusTimestamp(
                        (logs = job.logs),
                        (toStatus = 'accepted'),
                      ) | moment('L')
                    }}
                  </template>
                  <template v-else>-</template>
                </td>
                <td>
                  <template
                    v-if="
                      getJobToStatusTimestamp(
                        (logs = job.logs),
                        (toStatus = 'appointment confirmed'),
                      )
                    "
                  >
                    {{
                      getJobToStatusTimestamp(
                        (logs = job.logs),
                        (toStatus = 'appointment confirmed'),
                      ) | moment('L')
                    }}
                  </template>
                  <template v-else>-</template>
                </td>
                <td>
                  <template v-if="job.job.appointment_start_timestamp">
                    {{ job.job.appointment_start_timestamp | moment('L') }}
                    <br />
                    <small>
                      {{ job.job.appointment_start_timestamp | moment('H:mm') }}
                      -{{ job.job.appointment_end_timestamp | moment('H:mm') }}
                    </small>
                  </template>
                  <template v-else>-</template>
                </td>
                <td>
                  <template
                    v-if="
                      getJobToStatusTimestamp(
                        (logs = job.logs),
                        (toStatus = 'started'),
                      )
                    "
                  >
                    {{
                      getJobToStatusTimestamp(
                        (logs = job.logs),
                        (toStatus = 'started'),
                      ) | moment('L')
                    }}
                  </template>
                  <template v-else>-</template>
                </td>
                <td>
                  <template
                    v-if="
                      getJobToStatusTimestamp(
                        (logs = job.logs),
                        (toStatus = 'finished'),
                      )
                    "
                  >
                    {{
                      getJobToStatusTimestamp(
                        (logs = job.logs),
                        (toStatus = 'finished'),
                      ) | moment('L')
                    }}
                  </template>
                  <template v-else>-</template>
                </td>
                <td>
                  <template
                    v-if="
                      getJobToStatusTimestamp(
                        (logs = job.logs),
                        (toStatus = 'completed'),
                      )
                    "
                  >
                    {{
                      getJobToStatusTimestamp(
                        (logs = job.logs),
                        (toStatus = 'completed'),
                      ) | moment('L')
                    }}
                  </template>
                  <template v-else>-</template>
                </td>
                <td>
                  {{ statusesById[job.job.status] }}
                  <div
                    v-for="(status, idx) in compoundStateItems(job)"
                    :key="idx"
                  >
                    <b-badge variant="warning">{{ status }}</b-badge>
                  </div>
                </td>
                <td>{{ jobTypeDescription(job) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-if="invoices.length">
        <h4>Related Invoices</h4>

        <table class="table table-borderless table-sm">
          <thead>
            <th>Issue Date</th>
            <th>Invoice Number</th>
            <th>Order Value</th>
            <th>Currency</th>
            <th>Status</th>
            <th>Cancellation</th>
          </thead>
          <tbody>
            <tr v-for="(invoice, idx) in invoices" :key="idx">
              <td>{{ invoice.issue_date | moment('L') }}</td>
              <td>
                <a
                  :href="`/api/invoice/download/${invoice.path.replace(
                    'documents-api/file',
                    '',
                  )}?jwt=${access_token}`"
                  target="blank"
                >
                  {{ invoice.number }}
                </a>
              </td>
              <td>{{ invoice.total_amount.toFixed(2) }}</td>
              <td>{{ invoice.currency_symbol }}</td>
              <td>{{ invoice.status }}</td>
              <td>
                <template v-if="invoice.cancellation_number">
                  cancelled with
                  <a
                    :href="`/api/invoice/download/${invoice.cancellation_path.replace(
                      'documents-api/file',
                      '',
                    )}?jwt=${access_token}`"
                    target="blank"
                  >
                    {{ invoice.cancellation_number }}
                  </a>
                  on {{ invoice.cancellation_date | moment('L') }}
                </template>
                <template v-else>-</template>
              </td>
            </tr>
          </tbody>
        </table>
      </b-col>
      <b-col v-if="item.item.comments">
        <h4>Comments</h4>
      </b-col>
    </b-row>
    <b-modal
      ref="orderEidModal"
      :title="$t('projects.invoice-id.modal-title')"
      @ok="saveEID"
      :cancel-title="$t('cancel')"
    >
      <b-form-group :label="$t('projects.invoice-id.modal-label')">
        <b-form-input v-model="edit_eid_modal.eid" />
      </b-form-group>
    </b-modal>
    <UpdateInfoModal
      ref="updateInfoModal"
      :activeProductsGroup="activeProductsGroup"
      @callback="confirmOrder"
    />
  </b-container>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { INVOICING_EID } from '@gid/vue-common/components/inputs/_types';
import { isDKVMissingData } from '@gid/vue-common/utils';
import { JobStatusEnum } from '@gid/models';
import productOrdersMixin from '@/mixins/ProductOrdersMixin';

export default {
  mixins: [productOrdersMixin({ isProjectsPage: true })],
  props: {
    item: Object,
  },
  async created() {
    this.fetchStatuses();
  },
  data() {
    return {
      fields: [
        {
          label: this.$t('jobs.table.heading.project-id'),
          key: 'job.job.project_id',
        },
        {
          label: this.$t('jobs.table.heading.opportunity'),
          key: 'opportunity.name',
        },
        {
          label: this.$t('jobs.table.heading.order-value'),
          key: 'job._price',
        },
        {
          label: this.$t('jobs.table.heading.customer'),
          key: 'customer._fullname',
        },
        { label: this.$t('jobs.table.heading.status'), key: 'job.status' },
      ],
      statuses: [],
      edit_eid_modal: {
        job: null,
        eid: null,
        loading: false,
      },
    };
  },
  computed: {
    ...mapGetters(['locale', 'access_token']),
    accessToken() {
      return this.access_token;
    },
    latestOpenStatus() {
      return (job) => {
        return job.logs
          .filter((entry) => entry.new_values?.job?.status === 'open')
          .sort((x, y) => x.time_stamp - y.time_stamp)?.[0]?.time_stamp;
      };
    },
    statusesById() {
      return this.statuses.reduce((statuses, status) => {
        statuses[status.value] = status.text;
        return statuses;
      }, {});
    },
    invoices() {
      return this.item.item.invoices.filter((i) => i);
    },
    shouldDisplayQuote() {
      return (item) => {
        const statusesArray = [
          JobStatusEnum.PROPOSAL_APPROVAL_PENDING,
          JobStatusEnum.ACCEPTED,
          JobStatusEnum.ON_HOLD,
          JobStatusEnum.APPOINTMENT_CONFIRMED,
          JobStatusEnum.APPROVAL_PENDING,
          JobStatusEnum.FINISHED,
          JobStatusEnum.OPEN,
          JobStatusEnum.INVOICE_SENT,
          JobStatusEnum.COMPLETED,
        ];
        if (
          statusesArray.includes(item.job.status) &&
          this.item.item.opportunity.brand_can_view_quote
        ) {
          return true;
        }
        return false;
      };
    },
    shouldDisplayBrandLink() {
      return (item) => {
        const statusesArray = [
          JobStatusEnum.COMPLETED,
          JobStatusEnum.INVOICE_SENT,
          JobStatusEnum.OPEN,
          JobStatusEnum.CANCELLED,
          JobStatusEnum.ON_HOLD,
          JobStatusEnum.ACCEPTED,
          JobStatusEnum.FINISHED,
          JobStatusEnum.APPROVAL_PENDING,
          JobStatusEnum.CLOSED,
          JobStatusEnum.APPOINTMENT_CONFIRMED,
          JobStatusEnum.STARTED,
        ];
        return (
          item.job.status === JobStatusEnum.BRAND_PROPOSAL_APPROVAL_PENDING ||
          (statusesArray.includes(item.job.status) &&
            this.item.item.opportunity.brand_can_view_brand_quote)
        );
      };
    },
    checkProductDetails() {
      return this.productOrders?.length;
    },
  },
  methods: {
    getJobToStatusTimestamp(logs = [], toStatus = '') {
      const potential_events = logs.filter(
        (l) => l.event_type === 'new_status' && l.new_status === toStatus,
      );
      if (potential_events.length > 0) {
        return potential_events[0]['time_stamp'];
      }
      return false;
    },
    invoiceUrl(invoicePath) {
      return `/api/invoice/download/${invoicePath}?jwt=${this.accessToken}`;
    },
    jobTypeDescription(jobView) {
      if (jobView.is_precheck) {
        return 'precheck';
      }
      if (jobView.is_installation) {
        return 'installation';
      }
      if (jobView.is_activation) {
        return 'activation';
      }
      return 'unknown';
    },
    fetchStatuses() {
      axios.get(`/api/brand/orders/statuses`).then((response) => {
        this.statuses = response.data.map((status) => ({
          text: status.brand_name[this.locale],
          value: status.job_status,
        }));
      });
    },
    changeEID(job) {
      this.edit_eid_modal.job = job;
      this.edit_eid_modal.eid = job.invoicing_eid;
      this.$refs.orderEidModal.show();
    },
    saveEID() {
      this.edit_eid_modal.loading = true;
      axios
        .post(`/api/brand/orders/set-status`, {
          preview: false,
          new_status: null,
          job_id: this.edit_eid_modal.job.sfid,
          inputs: {
            [INVOICING_EID]: this.edit_eid_modal.eid,
          },
        })
        .then(({ data }) => {
          this.edit_eid_modal.job.invoicing_eid = data.job.invoicing_eid;
        })
        .finally(() => {
          this.edit_eid_modal.loading = false;
        });
    },
    isDKVMissingData(job_view) {
      return isDKVMissingData(job_view);
    },
    async getQuotePath(job_id) {
      const resp = await axios.get(`/api/brand/orders/${job_id}/quote`);
      const path = `/api/media/file/${resp.data}?jwt=${this.access_token}`;
      window.open(path, '_blank').focus();
    },
    async getBrandQuotePath(job_id) {
      const resp = await axios.get(`/api/brand/orders/${job_id}/brand-quote`);
      const path = `/api/media/file/${resp.data}?jwt=${this.access_token}`;
      window.open(path, '_blank').focus();
    },
    compoundStateItems(job_view) {
      return (
        job_view.job.states?.[0]?.startsWith(job_view.job.status) &&
        job_view.job.states?.[0].includes('.')
          ? job_view.job.states
          : []
      ).map((state) => this.$t(`state.${state}`));
    },
  },
};
</script>
